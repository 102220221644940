// Set your brand colors
@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ranchers&display=swap');

// Set your brand colors
$purple: #8A4D76;
$pink: #FA7C91;
$brown: #757763;
$beige-light: #D0D1CD;
$beige-lighter: #EFF0EB;
// Update Bulma's global variables
$family-open-sans: 'Open Sans', sans-serif;
$family-sans-serif: 'Ranchers', sans-serif;

$grey-dark: $brown;
$grey-light: $beige-light;
$primary: $purple;
$link: $pink;
$widescreen-enabled: false;
$fullhd-enabled: false;

$body-color: black;

// Update some of Bulma's component variables
$body-background-color: $beige-lighter;
$control-border-width: 2px;
$input-border-color: transparent;
$input-shadow: none;

$navbar-breakpoint: 568px;

// Import only what you need from Bulma
@import "../node_modules/bulma/bulma.sass";
//@import "../node_modules/bulma/sass/utilities/_all.sass";
//@import "../node_modules/bulma/sass/base/_all.sass";
//@import "../node_modules/bulma/sass/elements/_all.sass";
//@import "../node_modules/bulma/sass/form/_all.sass";
//@import "../node_modules/bulma/sass/components/_all.sass";
//@import "../node_modules/bulma/sass/layout/_all.sass";
// @import "../node_modules/bulma/sass/grid/_all.sass";
//@import "../node_modules/bulma/sass/utilities/_all.sass";
//@import "../node_modules/bulma-calendar/src/sass/index.sass";
@import "../node_modules/bulma-calendar/dist/css/bulma-calendar.min.css";
@import "../node_modules/bulma-timeline/dist/css/bulma-timeline.min.css";

html, body {
  background: url("/assets/images/background_lowres.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

h1 {
  font-size: 1.75em;
}

h2 {
  font-size: 1.50em;
}

h3 {
  font-size: 1.25em;
}

p, span {
  font-family: $family-open-sans;
}

span {
  font-family: $family-open-sans;
}

.splitbox {
  margin-top: 10px;
  padding: 10px;
  border-radius: 25px;
  border: 2px solid #000000;
  background-color: $body-background-color;
  opacity: 0.9;
}

.splitter-data-box {
  border-radius: 10px;
  border: 2px solid #000000;
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 10px;
  overflow-wrap: anywhere;

  h1 {
    font-size: 1.4em;
  }
}

.splitter-data-box-no-hover {
  border-radius: 10px;
  border: 2px solid #000000;
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 10px;
  overflow-wrap: anywhere;

  h1 {
    font-size: 1.0em;
  }
}

.splitter-data-box:hover {
  background-color: hsl(0, 0%, 48%) !important;
  color: white;
  cursor: pointer;
}

#toast-container.toast-top-center>div {
  width: 360px;
  margin-left: auto;
  margin-right: auto;
  background-color: black;
  border-radius: 25px;
  border: 2px solid #ffffff;
  font-size: 1.5em;
  align-content: center;
  }

  #toast-container > div > div.toast-message {
    text-align: center;
    padding-right: 30px;
  }
  .timeline .timeline-item .timeline-marker.is-primary.is-icon {
    background-color: black!important;
    border-color: black!important;
  }

  .timeline-item {
    border-color: black !important;
  }

.timeline .timeline-item {
    padding-bottom: 0px !important;
}
